let store = {
    debug: false,
    state: {
        tokenData: null,
    },
    setDataFromToken(newValue, from) {
        if (this.debug) console.log(`%c setDataFromToken triggered from ${from}`, 'color:green');
        this.state.tokenData = newValue;
    },
};

export default store;